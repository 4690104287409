import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';
import { link, details } from 'modules/landing/styles/Header.styles';

export const root = css`
  width: 100%;
`;

export const wrapper = css`
  padding: ${spacing(40)} ${spacing(20)} 0;
  overflow: hidden;

  @media ${breakpoints.medium} {
    padding: ${spacing(80)} ${spacing(20)} 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${spacing(40)};
  }

  @media ${breakpoints.large} {
    padding: ${spacing(80)} ${spacing(40)} 0;
    column-gap: ${spacing(80)};
    grid-template-columns: 480px auto;
  }
`;

export const content = css`
  margin-bottom: ${spacing(40)};

  @media ${breakpoints.medium} {
    max-width: ${spacing(480)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: ${spacing(40)};
  }

  @media ${breakpoints.large} {
    margin-top: ${spacing(80)};
  }
`;

export const header = css`
  max-width: 1312px;
  margin: 0 auto;
  padding: ${spacing(60)} ${spacing(20)};

  @media ${breakpoints.medium} {
    padding: ${spacing(160)} ${spacing(20)} ${spacing(120)};
  }
`;

export const title = css`
  font-size: ${spacing(32)};
  line-height: ${spacing(32)};
  text-transform: uppercase;
  margin-bottom: ${spacing(40)};

  @media ${breakpoints.medium} {
    font-size: ${spacing(60)};
    line-height: ${spacing(58)};
  }
`;

export const description = css`
  ${font.size.medium};
  color: hsl(var(--color-grayscale-6));
  margin-bottom: ${spacing(20)};

  @media ${breakpoints.medium} {
    ${font.size.large};
    margin-bottom: ${spacing(40)};
  }
`;

export const button = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

export const signUpBtn = css`
  ${link};
`;

export const card = css`
  ${details};
`;

export const illustration = css`
  position: relative;
  margin: 0 ${spacing(-20)};
  max-height: 740px;
  height: 100%;

  @media ${breakpoints.medium} {
    margin: 0 ${spacing(-20)};
    margin-left: 0;
    align-self: flex-end;
  }

  @media ${breakpoints.large} {
    margin-right: ${spacing(-40)};
  }

  @media ${breakpoints.xxLarge} {
    margin-top: ${spacing(-80)};
    height: auto;
  }
`;

export const illustrationImage = css`
  height: 100%;
`;
